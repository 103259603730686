import React from 'react';
import getConfig from 'next/config';
import {ArchiveFooter} from './ArchiveFooter';
import {ArchiveStyling} from '../../common/ArchiveStyling';
import {SiteMetadata} from '../../common/SiteMetadata';
import {SearchEngineOptimization} from '@/lib/types/searchEngineOptimization';
import {ArchiveHeader} from './ArchiveHeader';
import {GoogleAnalytics} from '@/components/common/GoogleAnalytics';
import {useRouter} from 'next/router';
import {extractFirstQueryValue} from '@/lib/utils/nextjs';
import {
  WordpressFooter,
  WordpressNavigation,
} from '@/features/archiveAsAProduct';
import {AnnouncementBanner} from '@/components/homepage/AnnouncementBanner';

type ArchivePageLayoutProps = Readonly<{
  navigation?: WordpressNavigation;
  footer?: WordpressFooter;
  children: React.ReactNode;
  seo?: SearchEngineOptimization;
}>;

const nextConfig = getConfig();
const hasAnnouncementBanner =
  nextConfig.publicRuntimeConfig?.featureFlags?.enableAnnouncementBanner ===
  'true';

/**
 * Renders the header and footer that will wrap the body of the page.
 * The AnnouncementBanner now fetches its own data and determines whether to display.
 */
export function ArchivePageLayout(props: ArchivePageLayoutProps) {
  const router = useRouter();
  const archive = extractFirstQueryValue(router, 'archive');
  const gtmID = function (archive) {
    switch (archive) {
      case 'hmca':
        return 'GTM-KVKTW52';
      case 'cfdata':
        return 'GTM-MWWQ7SG';
      case 'dsdr':
        return 'GTM-5MGP53K';
      case 'nadac':
        return 'GTM-NSRFTRW';
      case 'nacda':
        return 'GTM-N2RT9JQ';
      case 'nahdap':
        return 'GTM-54CPMXN';
      case 'nacjd':
        return 'GTM-PXFSJWQ';
      case 'pcodr':
        return 'GTM-T4Z383H';
      case 'sbeccc':
        return 'GTM-M3CD43L';
      case 'icpsr':
        return 'GTM-M9QDRN8';
      case 'datalumos':
        return 'GTM-KF6PH8H';
      case 'aera':
        return 'GTM-WD5V6G7';
      case 'somar':
        return 'GTM-KPGC6T46';
      case 'aea':
      case 'weai':
      case 'jeh':
      case 'psid':
      case 'ifip':
        return 'GTM-5X9C45K';
      default:
        return null;
    }
  };

  return (
    <>
      <GoogleAnalytics gtmId={gtmID(archive)} />
      <SiteMetadata seo={props.seo} />
      <ArchiveStyling>
        <div className="body-content">
          {hasAnnouncementBanner && <AnnouncementBanner />}

          <ArchiveHeader
            upperNavigation={props.navigation?.globalNavigation}
            lowerNavigation={props.navigation?.localNavigation}
            branding={props.navigation?.branding}
          />

          <main data-testid="main" id="skipto">
            {props.children}
          </main>

          <ArchiveFooter
            logo={props.footer?.logo}
            newsletter={props.footer?.newsletter}
            socialMediaLinks={props.footer?.socialMediaLinks}
            accessibility={props.footer?.accessibility}
            privacyPolicy={props.footer?.privacyPolicy}
            additionalLinks={props.footer?.additionalLinks}
            additionalLogos={props.footer?.additionalLogos}
            textBlurb={props.footer?.textBlurb}
          />
        </div>
      </ArchiveStyling>
    </>
  );
}
